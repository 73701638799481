.survey-request-dialog .survey-request__number-list {
  margin: 1em 0 0 0;
  padding: 0;
  list-style-type: none; }

.survey-request-dialog .survey-request__number-list li {
  counter-increment: step-counter;
  margin-bottom: 0.8em;
  font-family: 'Roboto-Regular';
  padding-top: 0.2em; }
  .survey-request-dialog .survey-request__number-list li::before {
    content: counter(step-counter);
    margin-right: 1.2em;
    font-size: 80%;
    background-color: transparent;
    color: #23ae84;
    font-family: 'Roboto-Bold';
    padding: 3px 8px;
    border-radius: 50%;
    border: #23ae84 1px solid;
    float: left;
    position: relative;
    top: -0.2em;
    width: 0.9em;
    text-align: center; }

.survey-request-dialog .survey-request__siren_bloc button[data-action="check-siren"] {
  margin-top: 1.3888em; }

.survey-request-dialog .dialog-icon-holder {
  width: 20.1875em;
  margin: 1.4286em auto;
  text-align: center;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 324.7334 63.52' enable-background='new 0 0 324.7334 63.52' xml:space='preserve'%3E %3Cg%3E %3Cpolygon fill='%23DBE6F4' points='0,63.52 60.9843,57.4488 62.8837,36.8875 1.8994,42.9588 '/%3E %3Cpolygon fill='%23EBF2FA' points='34.3193,48.0957 108.5704,40.7037 110.883,15.6694 36.6319,23.0615 '/%3E %3Cpolygon fill='%23EBF2FA' points='257.8757,28.3156 322.714,21.8606 324.7334,0 259.8951,6.4549 '/%3E %3Cpolygon fill='%23DBE6F4' points='184.2116,60.9738 248.3074,54.5928 250.3037,32.9825 186.2079,39.3635 '/%3E %3Cpolygon fill='%23EBF2FA' points='153.5089,46.9782 210.2439,41.3299 212.0109,22.2014 155.2759,27.8496 '/%3E %3Cpolygon fill='%23EBF2FA' points='100.2471,25.4365 151.9001,20.2942 153.5089,2.8791 101.8559,8.0214 '/%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }
  .survey-request-dialog .dialog-icon-holder svg {
    width: 8.0618em; }

.survey-request-dialog .last-step-container .dialog-body-title,
.survey-request-dialog .last-step-container .dialog-body-text {
  text-align: center; }

.survey-request-dialog .link {
  color: #004f9f; }

.survey-request-dialog #company_country_group .os-select,
.survey-request-dialog #survey_request_country_group .os-select,
.survey-request-dialog #survey_request_language_group .os-select {
  width: 100%; }

.survey-request-dialog .widget-phone-mdc {
  margin: 0; }
